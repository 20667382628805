div#CybotCookiebotDialog[data-template='popup'] {
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: initial;
  max-height: initial;
  max-width: initial;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.8);
  font-family: inherit;
}

#CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
  max-width: 56.25rem;
  max-height: 80%;
  background: #1d1b20;
  border-radius: 0.5rem;
}

/* This is an override for the Active button color configured in Cookiebot since we can't target it in the online editor. */
#CybotCookiebotDialogNav a.CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border: 0;
  border-bottom: 0.375rem solid #3f3f3f;
}

/* This is an override for the Text color configured in Cookiebot since we can't target it in the online editor. */
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
  color: white;
  font-size: 1.5rem;
}

/* This is an override for the Highlight color configured in Cookiebot since we can't target it in the online editor. */
#CybotCookiebotDialogTabContent input:checked + span.CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #8d8d8d;
}

#CybotCookiebotDialogFooter button.CybotCookiebotDialogBodyButton {
  border-radius: 2rem;
}

@media screen and (min-width: 1280px) {
  div#CybotCookiebotDialog #CybotCookiebotDialogFooter {
    padding: 1.5rem;
  }
}

#CybotCookiebotDialogFooter button.CybotCookiebotDialogBodyButton {
  padding: 0.7em 1em;
}

/* This is an override for the Accept all button configured in Cookiebot since we can't target it in the online editor. */
#CybotCookiebotDialogFooter button#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background: #e8bbfb;
  background-size: 200% 200%;
  border: 0.125rem solid transparent;
}
