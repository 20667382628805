@font-face {
  font-family: 'ITC Garamond Std Book Condensed';
  font-weight: 400;
  font-display: auto;
  src: url('/fonts/ITC-garamond-std-book-condensed.woff2') format('woff2');
}

@font-face {
  font-family: 'Suisse Int';
  font-weight: 400;
  font-display: auto;
  src: url('/fonts/suisse-int.woff2') format('woff2');
}
